import React from 'react'
import Layout from 'containers/Layout'
import Story from 'storybook/Story'
import { graphql } from 'gatsby'
import translations from 'config/translations'
import Meta from 'theme/atoms/Meta'
import {BannerProvider} from 'hooks/useBanner'

type Props = {
  location: {
    pathname: string
    key: string
  }
  data: {
    staticBlock: {
      story: any
    }
    banner: {
      title:string
      objectID:string
      popupCtaLabel:string
      popupCtaUrl:string
      popupDescription:string
      popupHintText:string
      popupTitle:string
      subtitle:string
      backgroundColor: string
      dummy?:boolean
    } | null
  }
}

export default function Homepage(props: Props) {
  const { staticBlock } = props.data
  const { story } = staticBlock
  
  return (
    <BannerProvider banner={props.data.banner}>
      <Layout partialStateUpdates={story.partialStateUpdates}>
        <Meta
          type="website"
          pathname={props.location.pathname}
          title={translations.meta.index.title}
          description={translations.meta.index.description}
          appleMobileAppIcon={true}
        />

        <Story key={props.location.key} story={story} />
      </Layout>
    </BannerProvider>
  )
}

export const query = graphql`
  query {
    staticBlock(id: { eq: "AeYMCJSjAs84WsQcA84mO" }) {
      story
    }
    banner(displayOnHomepage: {eq: true}) {
      title
      objectID
      popupCtaLabel
      popupCtaUrl
      popupDescription
      popupHintText
      popupTitle
      subtitle
      backgroundColor
      dummy
    }
  }
`
